import * as React from 'react'

import BlogList from '../Blog/List'
import { Container } from '../shared/Grid'
import { Flex } from '../shared/Helpers'
import Link from '../shared/Link'
import { Title } from '../shared/Typography'

interface IView {
  posts: Array<{ node: IQueryBlog }>
  message: string
}

const LatestPosts: React.FC<IView> = ({ message, posts }) => {
  return (
    <Container>
      <Flex
        mb="2em"
        flexWrap={['wrap', 'nowrap']}
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Title maxWidth="553px">{message}</Title>
        <Link color="blue" withIcon="arrow" to="/blog">
          View all blog posts
        </Link>
      </Flex>
      <BlogList posts={posts} />
    </Container>
  )
}

export default LatestPosts
