import { graphql, StaticQuery } from 'gatsby'
import * as React from 'react'

import { IProp, IRender } from './types'
import View from './view'

interface IReduce {
  prefers: Array<{ node: IQueryBlog }>
  others: Array<{ node: IQueryBlog }>
}

interface ICurr {
  node: IQueryBlog
}

const QueryLatestPosts: React.FC<IProp> = ({
  message,
  categories = [],
  excludesSlug = [],
  amount = 3,
}) => {
  return (
    <StaticQuery
      query={graphql`
        query LatestPosts {
          blogYaml {
            blog {
              blogMessage {
                title
                link
              }
              latestPosts {
                homepage
                careers
                events
                innerBlog
                partners
                projects
              }
            }
          }
          allMarkdownRemark(
            filter: { frontmatter: { category: { ne: null } } }
            sort: { fields: [frontmatter___date], order: DESC }
          ) {
            edges {
              node {
                excerpt(pruneLength: 100)
                fields {
                  slug
                  videoSnippet {
                    thumbnails {
                      high {
                        url
                      }
                    }
                    title
                    description
                  }
                }
                frontmatter {
                  date(formatString: "MMMM DD, YYYY")
                  title
                  category
                  author
                  videoId
                  hero {
                    childImageSharp {
                      fluid {
                        src
                        srcSet
                        sizes
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={({ allMarkdownRemark: { edges }, blogYaml }: IRender) => {
        const messageView: string = blogYaml.blog.latestPosts[message]

        // reduce posts by category and remove excludes
        const { prefers, others } = edges.reduce(
          (prev: IReduce, curr: ICurr) => {
            // check if it should be exclude
            if (excludesSlug.includes(curr.node.fields.slug)) {
              return prev
            }

            // looking for category
            const hasCategory = curr.node.frontmatter.category.some(r => {
              return categories.includes(r)
            })

            // check if it has and add to prefers
            if (hasCategory) {
              prev.prefers.push(curr)
            }

            // add to others one
            prev.others.push(curr)

            return prev
          },
          { prefers: [], others: [] }
        )

        // sort by date
        const posts: Array<{ node: IQueryBlog }> = [...prefers, ...others]
          .slice(0, amount)
          .sort((a, b) => {
            return (
              Number(new Date(b.node.frontmatter.date)) -
              Number(new Date(a.node.frontmatter.date))
            )
          })

        return <View message={messageView} posts={posts} />
      }}
    />
  )
}

export default QueryLatestPosts
